import {
  container,
  title,
  cardTitle,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
  grayColor
} from 'assets/jss/styles.js'
import carouselStyle from 'assets/jss/styles/components/carouselStyle';

const houseItemStyle = {
  carouselStyle,
  container,
  title,
  coloredShadow,
  cardTitle,
  mlAuto,
  mrAuto,
  description,
  cardCategory: {
    marginBottom: "40px",
    marginTop: "30px",
    fontSize: ".9rem",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "8px",
      lineHeight: "0"
    }
  },
  lowerCase: {
    textTransform: "lowercase",
  },
  description: {
    ...description,
    color: grayColor[1],
    lineHeight: "1.313rem",
    textAlign: "left",
    "& ul": {
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    "& li": {
      paddingBottom: "10px"
    }
  },
  card: {
    marginBottom: "80px",
    marginTop: "60px",
    padding: "20px 20px 20px 0"
  },
  card4: {
    marginBottom: "60px",
    textAlign: "center"
  },
  navButton: {
    "& svg": {
      marginRight: 0,
      marginLeft: "3px"
    }
  },
  content: {
    paddingLeft: "30px",
    '@media (max-width: 600px)': {
      textAlign: 'center',
    },
  },
  center: {
    textAlign: "center"
  }
};

export default houseItemStyle;